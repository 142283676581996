import React from "react";
import { graphql } from "gatsby";
// import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
import PlanCard from "../components/plan-card";
// import { FaBed, FaBath, FaWarehouse } from 'react-icons/fa';
import "../css/plans.css";

class PlansPage extends React.Component {
  render(){
    const {data} = this.props;
    const plans = data.allContentfulPlan.edges;

    console.log(plans);

    return (
      <Layout>
        <SEO title="Plans" />
        <div className="container">

          <div className="row">
            <div className="col-sm mt-auto">
              <h1 className="mt-4 mb-0">House Plans</h1>
            </div>
            <div className="col-sm-6 text-right mt-auto mb-2">
              {/* <button type="button" className="btn ml-auto" (click)="filter('type', 'All')" [ngclass]="filterClass('All')">All</button>
              <button type="button" className="btn ml-1" (click)="filter('type', '2 Story')" [ngclass]="filterClass('2 Story')">2 Story</button>
              <button type="button" className="btn ml-1" (click)="filter('type', 'Rambler')" [ngclass]="filterClass('Rambler')">Ramblers</button>
              <button type="button" className="btn ml-1" (click)="filter('bonus', true)" [ngclass]="filterClass('bonus')">Bonus Room</button> */}
            </div>
          </div>

          <div className="row plan-container mb-4">

        {plans.map(({ node }) => {
          return (
              <PlanCard key={node.slug} plan={node}/>
          )
        })}

      </div>

    </div>

      </Layout>
    )
  }
};

export default PlansPage;

export const pageQuery = graphql`
query {
  site {
    siteMetadata {
      title
      author
    }
  }
  allContentfulPlan(sort: {order: ASC, fields: order}) {
      edges {
        node {
          id
          name
          slug
          beds
          baths
          garage
          mainSqft
          basementSqft
          upstairsSqft
          bonus
          type
          order
          images {
            fluid {
              ...GatsbyContentfulFluid
            }
            file {
              url
              fileName
              contentType
            }
          }
          gallery {
            fluid {
              ...GatsbyContentfulFluid
            }
            file {
              url
              fileName
              contentType
            }
          }
        }
      }
    }
  }`
