import React from "react";
import Img from "gatsby-image";
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
import { Link } from "gatsby";
import { FaBed, FaBath, FaWarehouse } from 'react-icons/fa';

class PlanCard extends React.Component {
  constructor(...args) {
    super(...args);

    this.state = {};
  }

  render(){

    const plan = this.props.plan;
    // const totalSqft = plan.mainSqft + (plan.basementSqft ? plan.basementSqft : 0) + (plan.upstairsSqft ? plan.upstairsSqft : 0);
    const finishedSqft = plan.mainSqft + (plan.upstairsSqft ? plan.upstairsSqft : 0);

    return (

      <div className="col-sm-4 plan-col">
        <Link className="card plan-card" to={'/' + plan.slug}>
        {/* <div className="card plan-card" [routerlink]="['/plans/' + plan.name]">
          <img className="card-img-top" alt="" src={plan.images[0]} style={{width : '100%'}} />*/}
          <Img className="card-img-top" alt="" fluid={plan.images[0].fluid} />
          <div className="card-body">
            <div className="row">
              <div className="col">
                <h5 className="card-title">{plan.name}</h5>
              </div>
              <div className="col text-right">
                <h6 className="card-title">{finishedSqft} sq ft</h6>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col">
                <span className="badge badge-pill badge-secondary">{plan.type}</span>
              </div>
              <div className="col-8 text-right">
                {plan.beds}
                <FaBed className="ml-1 mr-3" />
                {plan.baths}
                <FaBath className="ml-1 mr-3" />
                {plan.garage}
                <FaWarehouse className="ml-1" />
              </div>
            </div>
          </div>
        </Link>
      </div>

    )
  }
};

export default PlanCard;
